<template>
  <div class="tw-time-entry-element !m-0 md:!mx-8 md:!mb-8 md:!mt-0 md:max-w-[280px]">
    <div :class="{ 'pointer-events-none': isEditorMode }">
      <template v-if="config.mode === 'dropdown-horizontal'">
        <TimeSelect
          v-model="selectedTime"
          :is-24="is24HourMode"
          :label-style="labelStyle"
          :hide-labels="!isLabelVisible"
          :locale="locale || 'en'"
          :page-options="pageOptions"
        />
      </template>
      <template v-else>
        <WebDatePicker
          v-model="selectedTime"
          :locale="locale || 'en'"
          :format="is24HourMode ? 'HH:mm' : 'hh:mm aa'"
          model-type="HH:mm"
          :placeholder="translate('generate.placeholder.time', locale)"
          time-picker
          text-input
          hide-input-icon
          :label-style="labelStyle"
          :label="labelText"
          :page-options="pageOptions"
          :is-24="is24HourMode"
          hide-cancel
        />
      </template>
    </div>
    <div v-if="$slots.default" class="tw-time-entry-element__bottom">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
    <div
      v-if="config.dontKnowButton?.show"
      class="text-center"
      :class="{ 'pointer-events-none': isEditorMode }"
    >
      <button
        :style="dontKnowButtonStyle"
        type="button"
        :disabled="isLoading"
        class="mt-16 inline-flex cursor-pointer items-center justify-center gap-8 self-center p-4 text-14"
        :class="{ 'opacity-40': isLoading}"
        @[btnClick]="handleDontKnowButtonClick"
      >
        <span class="text-center">{{ dontKnowButtonText }}</span>
        <span v-if="config.dontKnowButton?.icon"><img
          class="h-auto max-h-20 max-w-[20px]"
          :src="config.dontKnowButton?.icon"
          alt="next"
        /></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, type StyleValue, onBeforeMount } from 'vue';
import WebDatePicker from '@shared/components/datepicker/index.vue';
import TimeSelect from './components/time-select.vue'
import type { Element as ElementType, PageOptions } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { globalEmit } from '@shared/utils/helpers';
import { $wait } from '@shared/utils/wait';
import { useTranslate } from '@shared/composable/useTranslate';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';

const { translate } = useTranslate();

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['timeentry']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  locale: { type: String, default: '' },
  element: { type: Object as PropType<ElementType<'timeentry'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const selectedTime = ref(props.isEditorMode ? '' : props.config.defaultValue || '');
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const is24HourMode = computed(() => !props.config?.twelveHourFormat);

const { element, pageOptions } = toRefs(props);
const { labelStyle, labelText, isLabelVisible } = useLabelOptions({ element, pageOptions });

const values = computed(() => JSON.parse(props.element.values || '{}'));
const dontKnowButtonText = computed(() => values.value.dontKnowButtonText);
const dontKnowButtonStyle = computed(() => {
  const color = pageOptions.value.colors?.text[0];
  const fontFamily = pageOptions.value.fontFamily;
  const wordBreak = 'break-word';
  return {
    color,
    fontFamily,
    wordBreak
  } as StyleValue;
});
const isLoading = computed(() => $wait?.is(['nextQuestion', 'prevQuestion']) || false);

const canSubmit = computed(() => {
  if (props.config.required) {
    return !!selectedTime.value;
  }
  return true;
});

function finish(event?: any) {
  if (!canSubmit.value) return;
  globalEmit('nextQuestion', [event, selectedTime.value]);
}

function handleDontKnowButtonClick(event?: any) {
  const timeValue = props.config.required ? props.config.defaultValue || '12:00' : '';
  globalEmit('nextQuestion', [event, timeValue]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    selectedTime.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'timeentry',
    });
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
})
</script>

<style lang="postcss" scoped>
.tw-time-entry-element {
  :deep(.tw-datepicker) {
    &__menu {
      @apply w-full rounded-8;
    }
  }
}
</style>
